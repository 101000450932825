import "../stylesheets/ProfileJobs.css"

const ProfileJobs = ({ jobTitle, jobDesc }) => {
    return<>
    <div className="ProfileJobSection">
    <div className="ProfileJobTab">

      <div className="ProfileJobTitle">
      <center><h3>{jobTitle}</h3></center>
      </div>

      <div className="ProfileJobDesc">
        <h4>{jobDesc}</h4>
      </div>

      <div className="ProfileJobButton">
        <center>
          <button style={{width:'12vh',borderRadius:'1.75vh',marginTop:0,marginBottom:15}}>View</button>
          </center>
      </div>
      </div>
    </div>
    </>
  }

  export default ProfileJobs;