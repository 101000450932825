import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { BACKEND } from "../config";
import { deleteToken } from "../controllers/AuthController";

export default function useAxiosDefaults() {
  const [navToLogin, setNavToLogin] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()
  axios.defaults.baseURL = BACKEND
  axios.defaults.withCredentials = true

  // TODO: Maybe centralize error handling using this interceptor?
  // Automatically navigating user to login page if unauthorized code received
  axios.interceptors.response.use(function (response) {
    return response;
  }, function (error) {
    if (error.response?.status === 401) {
      deleteToken()
      setNavToLogin(true)
    }
    throw error
  })

  useEffect(() => {
    if (!navToLogin) {
      return;
    }
    navigate('/Login', { state: { from: location.pathname+location.search } })
    setNavToLogin(false)
  }, [navToLogin])
}