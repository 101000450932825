export function formatText(text) {
    const split = text.split('*')
    // Number of elements should be odd since even number of asterisks means odd number of segments e.g. "1*2*3"
    if (split.length % 2 === 0) {
        return text
    }

    const bold = []
    for (let i = 0; i < split.length; i++) {
        if (i % 2 === 0) {
            bold.push(split[i])
        } else {
            bold.push(<span style={{ fontWeight: 'bold' }}>{split[i]}</span>)
        }
    }
    return bold
}