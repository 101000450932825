import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import { useUser } from "../../hooks/useUser";
import './stylesheets/DownloadLink.css'

export default function DownloadLink({ children, url, requestBody, downloadName, disabled, onDownloadProgress = (e) => { }, style }) {
    const navigate = useNavigate()
    const location = useLocation()

    const curUser = useUser()

    async function handleDownload(e) {
        e.preventDefault()
        if(disabled) {
            return;
        }

        if (!curUser.loggedIn) {
            navigate('/Login', { state: { from: location.pathname + location.search } })
            return;
        }

        try {
            const result = await axios.post(
                url,
                requestBody,
                { responseType: 'blob', headers: { 'x-http-method-override': 'GET' }, onDownloadProgress }
            )

            const objURL = URL.createObjectURL(result.data)
            const a = document.createElement('a')
            a.href = objURL
            a.download = downloadName
            document.body.appendChild(a)
            a.click()

            URL.revokeObjectURL(objURL)
            document.body.removeChild(a)
        } catch (error) {
            console.log(error)
            alert(error)
        }
    }
    return <div className={"downloadable" + (disabled ? " disabled" : "")} style={style} onClick={handleDownload}>
        {children}
    </div>
}